import {graphql, useStaticQuery} from "gatsby";
import React from "react";
import {Instagram, Tiktok, Twitter, Youtube} from "@icons-pack/react-simple-icons";
import styled from "styled-components";

const SocialIconContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
`;

const SocialIconWrapper = styled.li`
  display: inline-block;
  padding: 1rem;
  border-radius: 5px;
  
  svg {
    fill: #fff;
    height: 35px;
    width: 35px;
    
    &:hover {
      opacity: .6;
    }
  }
`;

const SocialIcons = () => {
  const { site: { siteMetadata: { socials } } }  = useStaticQuery(graphql`
      query MyQuery {
          site {
              siteMetadata {
                  socials {
                      instagram
                      twitter
                      youtube
                      tiktok
                  }
              }
          }
      }
  `);

  interface ElementMap {
    [key: string]: React.FC;
  }

  const map: ElementMap = {
    "instagram": Instagram,
    "twitter": Twitter,
    "youtube": Youtube,
    "tiktok": Tiktok,
  }

  return <SocialIconContainer>
    { Object.entries(socials).map(([key, url]) => {
      const SocialIcon = map[key];
      if (!SocialIcon || !url) {
        return <></>
      }
      return (
        <SocialIconWrapper key={key}>
          <a href={url} target={'_blank'} rel={'noreferrer'}>
            <SocialIcon />
          </a>
        </SocialIconWrapper>
      )
    }) }
  </SocialIconContainer>
}

export default SocialIcons;
