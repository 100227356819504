import React from "react";
import {Wrapper} from "../components/utils/Wrapper";
import styled from "styled-components";
import {StaticImage} from "gatsby-plugin-image";

const HeroWrapper = styled.header`
  background: #eee;
  position: relative;
  overflow: hidden;

  @media screen and (min-width: 750px) {
    max-height: 100vh;
  }
  
  .content {
    background: #151e2f;
    color: #fff;
    padding: 1rem 0;

    @media screen and (min-width: 750px) {
      z-index: 500;
      padding: 1rem 2rem;
      position: absolute;
      bottom: 20%;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      width: auto;
    }
  }
  
  .header {
    @media screen and (min-width: 750px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    h1 {
      margin: 0 1rem 0 0;
    }
    
    p {
      margin: 0;
    }
  }
  
  
  #hero-image {
    pointer-events: none;
  }
`;

const Hero = () => {
  return (
    <HeroWrapper>
      <div className="content">
        <Wrapper>
          <div className="header">
            <h1>Rickesh Patel</h1>
            <p>Rickesh Patel is a UK based Commercial, Lifestyle & Hand Model and Entrepreneur</p>
          </div>
        </Wrapper>
      </div>
      <div id={"hero-image"}>
        <StaticImage
          src="../images/hero-banner.jpg"
          placeholder="blurred"
          transformOptions={{ fit: "cover" }}
          quality={100}
          layout={"constrained"}
          alt="Rickesh Patel" />
      </div>
    </HeroWrapper>
  )
}

export default Hero;
