import React from "react";
import styled from "styled-components";

const SectionHeaderElement = styled.h2`
  padding: 2rem 0;
  margin-bottom: 2rem;
  margin-top: 2rem;
  border-bottom: 1px solid #fff4;

  @media screen and (min-width: 750px) {
    margin-top: 4rem;
  }
`

const SectionHeader = ({ title }: { title: string }) => {
  return (
    <SectionHeaderElement>{ title }</SectionHeaderElement>
  )
}

export default SectionHeader;
