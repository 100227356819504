import {createGlobalStyle} from "styled-components";

export const GlobalStyle = createGlobalStyle`
  
  * { box-sizing: border-box; }
  
  html,
  body,
  #gatsby-focus-wrapper,
  #___gatsby {
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
    font-family: 'Open Sans', sans-serif;
    background: #151e2f;
    color: #fff;
    font-size: 16px;
    letter-spacing: .8px;
    text-rendering: optimizeLegibility;
  }
  
  body {
    margin: 0;
  }
  
  hr {
    border: 1px solid #eee;
    position: relative;
  }
`
