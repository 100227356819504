import React from "react";
import SectionHeader from "../components/SectionHeader";
import { Wrapper } from "../components/utils/Wrapper";
import styled from "styled-components";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";

const LogoGrid = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  justify-content: space-between;
  
  li {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48%;

    @media screen and (min-width: 750px) {
      width: auto;
    }
    
    img {
      width: 100%;
      height: auto;
      border-radius: 5px;
    }
  }
`;

const Clients: React.FC = () => {

  const { clientGrid } = useStaticQuery(graphql`
      query {
          clientGrid: contentfulLogoGrid {
              title
              logos {
                  id
                  filename
                  description
                  gatsbyImage(layout: FIXED, quality: 100, width: 200)
              }
          }
      }
  `);

  const { logos, title } = clientGrid;

  return (
    <section id={"clients"}>
      <Wrapper>
        <SectionHeader title={title} />
        <LogoGrid>
        { logos.map((logo: any) => (
          <li key={logo.id} id={logo.id}>
            <GatsbyImage
              image={logo.gatsbyImage}
              imgStyle={{objectFit: 'contain'}}
              alt={logo.description}
            />
          </li>
        ))}
        </LogoGrid>
      </Wrapper>
    </section>
  )
}

export default Clients;
