import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import GallerySection from "./GallerySection";

const Gallery: React.FC = () => {

  const { photoSets } = useStaticQuery(graphql`
      query {
          photoSets: allContentfulGallery(sort: {fields: order, order: ASC}) {
              nodes {
                  id
                  photos {
                      id
                      filename
                      gatsbyImage(layout: FIXED, quality: 100, width: 400)
                      description
                  }
                  title
              }
          }
      }
  `);

  return (
    <>
      { photoSets.nodes.length > 0 ? photoSets.nodes.map((data: any) => {
        return <GallerySection key={data.id} data={data} />
      } ) : '' }
    </>
  )
}

export default Gallery;
