import React from "react";
import styled from "styled-components";
import {Wrapper} from "../components/utils/Wrapper";
import SocialIcons from "../components/SocialIcons";

const StyledFooterContainer = styled.footer`
  margin-top: 2rem;
  //color: #3338;
  
  a { color: inherit; text-decoration: none; }
`

const StyledFooterInner = styled.div`
  border-top: 1px solid #fff4;
  padding: 2rem 0;
  text-align: center;
  line-height: 1;
  
  p {
    margin: 0;
    line-height: 1.5;
  }
  
  .credit {
    display: block;
  }

  @media screen and (min-width: 750px) {
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: center;
    
    .credit { display: inline-block; }
  }
`;

const Footer: React.FC = () => {
  return (
    <StyledFooterContainer>
      <Wrapper>
        <StyledFooterInner>
        <p>Copyright &copy; { new Date().getUTCFullYear() } Rickesh Patel - All rights reserved. <a className={'credit'} target={'_blank'} href="https://jamesrwilliams.ca">Website by JRW</a></p>
        <SocialIcons />
        </StyledFooterInner>
      </Wrapper>
    </StyledFooterContainer>
  )
}

export default Footer;
