import React from "react";
import {Wrapper} from "../components/utils/Wrapper";
import styled from "styled-components";
import SocialIcons from "../components/SocialIcons";

const AboutWrapper = styled(Wrapper)`
  max-width: 600px;
  text-align: center;
  line-height: 1.4;
  padding: 3rem 1rem;

  a {
    color: #fff;
  }
  
  h2 {
    margin: 0;
  }

  @media screen and (min-width: 750px) {
    padding: 6rem 0 3rem;
  }
`;

const About: React.FC = () => {
  return (
    <section id={'about'}>
      <AboutWrapper>
        <h2>About Rickesh Patel</h2>
        <p>Rickesh Patel is a UK based Commercial, Lifestyle & Hand Model and Entrepreneur</p>
        <p>For bookings please email <a href="mailto:contact@rickesh-patel.com">contact@rickesh-patel.com</a>.</p>
        <SocialIcons />
      </AboutWrapper>
    </section>
  )
}

export default About;
