import * as React from "react"
import Layout from "../components/Layout";
import Helmet from "react-helmet"
import Hero from "../sections/Hero";
import Footer from "../sections/Footer";
import Gallery from "../components/Gallery";
import About from "../sections/About";
import Clients from "../sections/Clients";
import SEO from "../components/seo";

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title=""
        description={'Rickesh Patel is a UK based Commercial, Lifestyle & Hand Model and Entrepreneur'}
      />
      <Helmet title={'Rickesh Patel • UK based Commercial, Lifestyle & Hand Model and Entrepreneur'}>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin={"anonymous"} />
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;600&display=swap" rel="stylesheet" />
      </Helmet>
      <Hero />
      <About />
      <Gallery />
      <Clients />
      <Footer />
    </Layout>
  )
}

export default IndexPage
