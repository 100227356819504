import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import styled from "styled-components";
import { Wrapper } from "./utils/Wrapper";
import SectionHeader from "./SectionHeader";

const PhotoGalleryContainer = styled.section``;

const PhotoGallerySliderContainer = styled.div`
  position: relative;
`;

const PhotoGallerySlider = styled.ul`
  display: flex;
  max-width: 100%;
  overflow: scroll;
  margin: 0;
  padding: 0 1rem;
  
  li {
    list-style: none;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    pointer-events: none;
    margin-bottom: 1rem;
    margin-right: 1rem;
    border-radius: 5px;
    scale: 90%;
    
    @media screen and (min-width: 750px) {
      width: auto;
      scale: 100%;
      margin-right: 4rem;
      margin-left: 0;
    }
  }
  
  li:first-child {
    @media screen and (min-width: 750px) {
      margin-left: calc((100vw - 970px)/2);
    }
  }
`;

const GallerySection: React.FC<{ data: any }> = ({ data }) => {

  const { title, photos } = data;

  if(!photos) {
    return <></>
  }

  return <PhotoGalleryContainer>
    <Wrapper>
      <SectionHeader title={title} />
    </Wrapper>
    <PhotoGallerySliderContainer>
      <PhotoGallerySlider>
        { photos.map((photo: any) => (
          <li key={photo.id} id={photo.id}>
            <GatsbyImage
              image={photo.gatsbyImage}
              imgStyle={{objectFit: 'contain'}}
              alt={"data.blogPost.author"}
            />
          </li>
          ))}
      </PhotoGallerySlider>
    </PhotoGallerySliderContainer>
  </PhotoGalleryContainer>
}

export default GallerySection;
