import React from "react";
import {GlobalStyle} from "../styles/globalStyles";
import { Wrapper } from "./utils/Wrapper";

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <main>
        { children }
        <GlobalStyle />
      </main>
    </>
  )
}

export default Layout;
